import { Button, Form } from "react-bootstrap";
import { requireInteger } from "../../../../utilities/FormUtility";
import { SectionHeader } from "../../../SectionHeader/SectionHeader";

export function LauncherSettings() {
    return (
        <div>
            <SectionHeader underlined>Update Launcher Settings</SectionHeader>
            <form>
                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Change Launcher File</Form.Label>
                    <Form.Control type="file" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Launcher Version</Form.Label>
                    <Form.Control type="number" min={0} onKeyPress={requireInteger}/>
                </Form.Group>
                <div className="d-flex justify-content-end">
                    <Button variant="primary">Save Launcher Settings</Button>
                </div>
            </form>
        </div>
    )
}